import {
    isIOS,
    isAndroid,
    isChrome,
    isSafari,
    isBrowser,
} from "react-device-detect";

export const getUserAgent = () => navigator.userAgent;
export const getHash = () => window.location.pathname.substr(1);
export const isWalletPasses = () => navigator.userAgent.includes("WalletPasses");

export function getBrowserType() {
    if (isChrome) return 1;
    else if (isSafari) return 2;
    else return 0;
}

export function getOSType() {
    if (isWalletPasses()) return 3;
    if (isAndroid) return 2;
    if (isIOS) return 1;
    if (isBrowser) return 4;
    return 0;
}