import React, { useContext, useState } from 'react'

// Material UI
import { FormHelperText, Typography, FormGroup, Checkbox, Stack } from '@mui/material';

// Components
import Dialog from './dialog.component';

// Contexts
import { DataContext } from '../_contexts/data.context';

const Consent = (props) => {
    const { consent } = props;

    const { appState, useConsent } = useContext(DataContext);

    const [open, setOpen] = useState(false);

    const handleConsentDialog = () => setOpen(!open);

    const { value, onChange, onFocus, error, required, helperText } = useConsent(consent);

    return (
        <div>
            <FormGroup row onChange={onChange} style={{ flexWrap: 'nowrap' }}>
                <Checkbox
                    name={consent.Name}
                    color='primary'
                    disabled={appState.submitting}
                    required={required}
                    onFocus={onFocus}
                    value={value} />
                <Stack sx={{ flexDirection: 'column', justifyContent: 'center', maxwidth: '85%', cursor: 'pointer' }} onClick={handleConsentDialog}>
                    <Typography
                        disabled={appState.submitting}
                        color='secondary'
                        variant='subtitle2'
                        style={{ textDecoration: 'underline' }}>
                        {consent.Text}
                    </Typography>
                </Stack>
            </FormGroup>
            <FormHelperText
                error={error}
                disabled={appState.submitting}
                style={{ paddingLeft: '15px' }}>
                {helperText || ' '}
            </FormHelperText>
            <Dialog open={open} fullScreen onClose={handleConsentDialog} dialogText={consent.TermsOfUse} captionText={consent.Caption} />
        </div>
    )
}

export default Consent;