import { useState, useCallback, useEffect } from 'react';
import { find, union } from 'lodash';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { phoneformats } from '../_helpers/localization.helper';
import moment from 'moment';

export function useFormInput({ field, values, validating, setValues, setValidating, handleError }) {
  const name = field.Name;
  const isrequired = field.Required;
  const inputtype = field.InputType;
  const fields = values.FormData;
  let formValue = '';
  for (var key in fields) {
    if (key === name) formValue = fields[key];
  }

  const [value, setValue] = useState(formValue);
  const [isValid, setIsValid] = useState(true);
  const [isTouched, setIsTouched] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleValidation = useCallback(() => {
    const checkvalid = validate(value, isrequired, field);
    setIsValid(checkvalid);
    handleError(name, checkvalid);
  }, [setIsValid, name, value, isrequired, field, handleError]);

  // watch for external parent data changes
  useEffect(() => {
    if (value !== formValue) {
      if (formValue === '') setValue(formValue);
      setIsTouched(false);
      setIsFocused(false);
    }
  }, [formValue, value, setValue, setIsFocused, setIsTouched]);

  // validate on value change
  useEffect(() => {
    handleValidation();
  }, [value, handleValidation]);

  // rewrite self and parent's value
  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      var formattedvalue = value;

      if (inputtype === 'regex-phone') {
        const { valid, formattedphone } = validateRegexPhone(value, field);
        valid ? (formattedvalue = formattedphone) : (formattedvalue = value);
      }

      fields[name] = formattedvalue;

      setValidating(false);
      setValue(value);
      setValues((prev) => ({ ...prev, FormData: fields }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [setValue, setValues, setValidating, name, inputtype, fields]
  );

  const handleFocus = useCallback(() => {
    setIsTouched(true);
    setIsFocused(true);
    handleValidation();
  }, [setIsTouched, setIsFocused, handleValidation]);

  const handleBlur = useCallback(() => setIsFocused(false), [setIsFocused]);

  const error = validating ? !isValid : !isValid && isTouched && !isFocused;
  const helpertext = error ? field.ValidationText : field.HelperText;

  return {
    name,
    value,
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    error,
    required: isrequired,
    helperText: helpertext || ' ',
  };
}

export const validate = (value, isrequired, field) => {
  if (isrequired && !value) return false;

  if (field.Regex) {
    if (!isrequired && !Boolean(value)) return true;
    const regex = new RegExp(field.Regex);
    return regex.test(value);
  }

  if (field.InputType === 'select') {
    if (!isrequired && !Boolean(value)) return true;
    return value !== null;
  }

  if (field.InputType === 'email') {
    if (!isrequired && !Boolean(value)) return true;
    var re_email =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re_email.test(String(value).toLowerCase());
  }

  if (field.InputType === 'phone') {
    if (!isrequired && (value === '' || value === field.InputMask.replace(/9/gi, ' '))) return true;
    var re_phone = new RegExp(field.Regex);
    return re_phone.test(String(value).toLowerCase());
  }

  if (field.InputType === 'masked-text') {
    if (!isrequired && !Boolean(value)) return true;
    var re_masked_text = new RegExp(field.Regex);
    return re_masked_text.test(String(value).toLowerCase());
  }

  /*if (field.InputType === 'regex-phone') {
        if (!isrequired && !Boolean(value)) return true;
        const { valid, } = validateRegexPhone(value, field);
        return valid;
    }

    if (field.InputType === 'int-phone') {
        if (!isrequired && !Boolean(value)) return true;
        return field.country.reg.test(String(value).toLowerCase());
    }*/

  if (field.InputType === 'tr-mobile') {
    if (!isrequired && !Boolean(value)) return true;
    var re_mobile =
      /^(500|501|502|503|504|505|506|507|508|509|510|516|522|530|531|532|533|534|535|536|537|538|539|540|541|542|543|544|545|546|547|548|549|550|551|552|553|554|555|556|557|558|559|561|592)([0-9]{7})$/;
    return re_mobile.test(String(value).toLowerCase());
  }

  if (field.InputType === 'date') {
    const formatted = value.split('-').reverse().join('.');

    if (!isrequired && (value === '' || value === field.InputMask.replace(/9/gi, ' '))) return true;
    var re_date = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\.?(0[1-9]|1[0-2])\.?(19|20)\d{2}$/;
    return re_date.test(String(formatted).toLowerCase());
  }

  if (field.InputType === 'masked-date') {
    if (!isrequired && !Boolean(value)) return true;
    var re_maskeddate = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
    return re_maskeddate.test(String(value).toLowerCase());
  }

  if (field.InputType === 'age-control') {
    var compareDate = moment(value, 'YYYY-MM-DD');
    var startDate = moment('27-10-1995', 'DD-MM-YYYY');
    var endDate = moment('18-01-2004', 'DD-MM-YYYY');

    return compareDate.isBetween(startDate, endDate);
  }

  if (field.InputType === 'age-control18') {
    var compareDate = moment(value, 'YYYY-MM-DD');

    return moment().diff(compareDate, 'years') >= 18;
  }

  return true;
};

const validateRegexPhone = (value, field) => {
  // First check the length of the phone number. We do not allow phone numbers less that 8 chars
  if (value.length < 8) return { valid: false, formattedphone: '' };

  var valid = false;
  var phonenumber = '';
  var formattedphone = '';

  // Create a list of country codes to be checked for international phone number format library
  // We unique merge the list that comes with the field and the country code we fetched from IP
  const checklist = union([field.countrycode?.toUpperCase()], field.Countries);

  // First check if the phone number is a valid international phone number
  if (isValidPhoneNumber(value)) {
    phonenumber = parsePhoneNumber(value);
    formattedphone = phonenumber.format('INTERNATIONAL');
    valid = phonenumber.isValid();

    if (phonenumber && phonenumber.country === 'TR') {
      const format = find(phoneformats, ['iso2', 'tr']);
      valid = format?.reg?.test(formattedphone);
    }
  } else {
    // The phone number is NOT a valid int-phone. We will check the phone number against every country in our checklist
    var i;

    for (i = 0; i < checklist.length; i++) {
      const country = checklist[i];

      if (isValidPhoneNumber(value, country)) {
        phonenumber = parsePhoneNumber(value, country);
        formattedphone = phonenumber.format('INTERNATIONAL');
        valid = phonenumber.isValid();

        if (phonenumber && phonenumber.country === 'TR') {
          const format = find(phoneformats, ['iso2', 'tr']);
          valid = format?.reg?.test(formattedphone);
        }

        break;
      }
    }
  }

  return { valid, formattedphone };
};
