import { useState, useCallback, useEffect } from 'react';

export const useFormConsent = ({
    consent,
    values,
    validating,
    setValues,
    setValidating,
    handleError
}) => {
    const fields = values.FormData;
    const name = consent.Name;
    const isrequired = consent.isRequired;

    let formValue;
    for (var key in fields) {
        if (key === String(name)) formValue = fields[key]

    }

    const [value, setValue] = useState(formValue);
    const [isValid, setIsValid] = useState(true);
    const [isTouched, setIsTouched] = useState(false);

    const handleValidation = useCallback(() => {
        const valid = isrequired ? (value) : true
        setIsValid(valid);
        handleError(name, valid);
    }, [setIsValid, name, value, isrequired, handleError]);

    // validate on value change
    useEffect(() => {
        handleValidation();
    }, [value, handleValidation]);

    // rewrite self and parent's value
    const handleChange = useCallback((event) => {
        const value = (event.target.checked ? true : false);

        fields[name] = value

        setValidating(false);
        setValue(value);
        setValues(prev => ({ ...prev, FormData: fields }));
    }, [setValue, setValues, setValidating, name, fields]);

    const handleFocus = useCallback(() => {
        setIsTouched(true);
        handleValidation();
    }, [setIsTouched, handleValidation]);

    const error = validating ? !isValid : !isValid && isTouched;
    const helpertext = error ? consent.ValidationText : consent.HelperText;

    return {
        name,
        value,
        onChange: handleChange,
        onFocus: handleFocus,
        error,
        required: isrequired,
        helperText: helpertext,
    };
}