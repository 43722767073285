import axios from 'axios';
import handleError from './handleerror';

import AESGCM from './aesgcm';

const date = new Date();

const SERVER_DOMAIN = process.env.REACT_APP_API_URL;
const CLIENT_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_SECRET = process.env.REACT_APP_API_SECRET;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';
axios.defaults.crossDomain = true;
axios.defaults.preflightContinue = true;
axios.defaults.withCredentials = false;

const header = {
    ApiClientKey: CLIENT_KEY,
    ApiClientSecret: CLIENT_SECRET,
    Timestamp: parseInt(date.getTime() / 1000) - 300
}


const { encrypt } = AESGCM();
const encrypted = encrypt(JSON.stringify(header))

export const getdatafromip = (path) => {
    return new Promise((resolve, reject) => {
        axios.get(path)
            .then(response => resolve(response.data.Data))
            .catch(error => reject(handleError(error)));
    });
};

export const get = (path) => {
    return new Promise((resolve, reject) => {
        axios.get(`${SERVER_DOMAIN}/${path}`)
            .then(response => resolve(response.data.Data))
            .catch(error => reject(handleError(error)));
    });
};

export const post = (path, data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${SERVER_DOMAIN}/${path}`, data, { headers: { 'Authorization': encrypted } })
            .then(response => resolve(response.data.Data))
            .catch(error => reject(handleError(error)));
    });
};

export const postExternal = (externalUrl, data) => {
    return new Promise((resolve, reject) => {
        axios.post(externalUrl, data, { headers: { 'Authorization': encrypted } })
            .then(response => resolve(response.data.Data))
            .catch(error => reject(handleError(error)));
    });
};