import React, { useContext } from 'react'


// Material UI
import TextField from '@mui/material/TextField';

// Contexts
import { DataContext } from '../../_contexts/data.context';

const SelectField = (props) => {
    const { field, classes } = props;
    const { appState, useInput } = useContext(DataContext); // Extract Forms and Credentials from context
    const Shrink = (field.PlaceHolder) ? { shrink: true, } : null;

    return <TextField
        select
        name={field.Name}
        label={field.Label}
        placeholder={field.PlaceHolder}
        disabled={field.Disabled || appState.submitting}
        fullWidth
        variant={field.Variant}
        InputLabelProps={Shrink}
        className={classes.textfield}
        InputProps={{
            classes: {
                input: classes.input,
            }
        }}
        SelectProps={{ native: true }}
        {...useInput(field)}
    >
        {field.ValueTexts.map((Value, i) => (
            <option key={field.Values[i]} value={field.Values[i]}>
                {Value}
            </option>
        ))}
    </TextField>
}

export default SelectField;