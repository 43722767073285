import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { CookiesProvider } from 'react-cookie';
import './index.css';

// Pages
import Error from './_pages/error.page';
import App from './app';

// Context
import { DataProvider } from './_contexts/data.context';

const ErrorFallback = ({ error, }) => <Error error={error} language={'en'} />;

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => { }} >
    <CookiesProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </CookiesProvider>
  </ErrorBoundary>
  , document.getElementById('root'));
