import React, { useContext } from 'react'

// Material UI
import TextField from '@mui/material/TextField';

// Contexts
import { DataContext } from '../../_contexts/data.context';

const EmailField = (props) => {
    const { field, classes } = props;
    const { appState, useInput } = useContext(DataContext);
    const Shrink = (field.PlaceHolder) ? { shrink: true, } : null;

    return <TextField
        name={field.Name}
        label={field.Label}
        placeholder={field.PlaceHolder}
        disabled={field.Disabled || appState.submitting}
        fullWidth
        variant={field.Variant}
        InputLabelProps={Shrink}
        type='email'
        className={classes.textfield}
        InputProps={{
            classes: {
                input: classes.input,
            }
        }}
        {...useInput(field)}
    />
}

export default EmailField;