import React, { useContext } from 'react';

// Material UI
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { StylesProvider } from '@mui/styles';

// Pages
import Form from './_pages/form.page';
import Page from './_pages/page.page';
import Error from './_pages/error.page';
import Splash from './_pages/splash.page';
import Success from './_pages/success.page';

// Contexts
import { DataContext } from './_contexts/data.context';

// Helpers
import { DeepLinker } from './_helpers/redirect.helper';

const App = () => {
	const { appState, formGetResponse, formSendResponse, theme } = useContext(DataContext);

	if (appState.state === 'resting') return <Splash />;
	if (appState.state === 'checking') return <Splash />;
	if (appState.state === 'error') return <Error error={appState.error} />;

	var linker = new DeepLinker({
		onIgnored: function () {},
		onFallback: function () {},
		onReturn: function () {},
	});

	return (
		<Container disableGutters maxWidth="sm">
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<StylesProvider>
					{(() => {
						switch (appState.state) {
							case 'form':
								return <Form />;
							case 'page':
								return (
									<Page
										page={formGetResponse.Form?.Config?.Pages.find((page) => page.Id === (formSendResponse?.Page || formGetResponse?.Page))}
									/>
								);
							case 'success': {
								if (formSendResponse.RedirectURL) {
									linker.openURL(formSendResponse?.RedirectURL);
								}
								return <Success />;
							}
							default:
								return '';
						}
					})()}
				</StylesProvider>
			</ThemeProvider>
		</Container>
	);
};
export default App;
