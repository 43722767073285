const handleError = (error) => {
    const { message, response } = error;

    console.log('error ->', error);
    console.log('message ->', message);
    console.log('response ->', response);

    if (message === 'Network Error') // We could not connect to api
        return { code: 503, message: 'Service Unavailable', status: 503 };

    return { code: response.data.Code, message: response.data.Message, status: response.status };
}

export default handleError;