import React, { useContext } from 'react'

import Typography from '@mui/material/Typography';

import { DataContext } from '../../_contexts/data.context';

const InfoText = (props) => {
    const { field } = props;
    const { formGetResponse, formSendResponse } = useContext(DataContext);

    const getConfirmMessage = (text) => {
        if (text) {
            if (formSendResponse) {
                let message = text;
                if (text.includes('@')) {
                    message.split(' ').map(param => {
                        if (param.startsWith('@')) message = message.replace(param, formSendResponse[param.substr(1)])
                        return null;
                    });
                }
                return message
            }
            else {
                let message = text;
                if (text.includes('@')) {
                    message.split(' ').map(param => {
                        if (param.startsWith('@')) message = message.replace(param, formGetResponse[param.substr(1)])
                        return null;
                    });
                }
                return message
            }
        }
        return ''
    }

    return <Typography sx={{ whiteSpace: 'break-spaces' }} color='primary' textAlign='center' variant={field.Variant} key={field.Label} gutterBottom>{getConfirmMessage(field.Label)}</Typography>
}

export default InfoText
