export const phoneformats = [
    {
        name: 'Azerbaijan',
        iso2: 'az',
        dialcode: '994',
        mask: '+\\9\\94 99-999 9999',
        reg: /(\+994) ([0-9]{2})-([0-9]{3}) ([0-9]{4})/
    },
    {
        name: 'Bahrain',
        iso2: 'bh',
        dialcode: '973',
        mask: '+\\973 9999 9999',
        reg: /(\+973) ([0-9]{4}) ([0-9]{4})/
    },
    {
        name: 'Canada',
        iso2: 'ca',
        dialcode: '1',
        mask: '+1 999 999-9999',
        reg: /(\+1) ([0-9]{3}) ([0-9]{3})-([0-9]{4})/
    },
    {
        name: 'China',
        iso2: 'cn',
        dialcode: '86',
        mask: '+86 99-999999999',
        reg: /(\+86) ([0-9]{2})-([0-9]{9})/
    },
    {
        name: 'Egypt',
        iso2: 'eg',
        dialcode: '20',
        mask: '+20 99-999-9999',
        reg: /(\+20) ([0-9]{2})-([0-9]{3})-([0-9]{4})/
    },
    {
        name: 'France',
        iso2: 'fr',
        dialcode: '33',
        mask: '+33 9 99 99 99 99',
        reg: /(\+33) ([0-9]{1}) ([0-9]{2}) ([0-9]{2}) ([0-9]{2}) ([0-9]{2})/
    },
    {
        name: 'Germany',
        iso2: 'de',
        dialcode: '49',
        mask: '+4\\9 9999 99999999',
        reg: /(\+49) ([0-9]{4}) ([0-9]{8})/
    },
    {
        name: 'Italy',
        iso2: 'it',
        dialcode: '39',
        mask: '+3\\9 999 9999999',
        reg: /(\+39) ([0-9]{3}) ([0-9]{7})/
    },
    {
        name: 'Kuwait',
        iso2: 'kw',
        dialcode: '965',
        mask: '+\\965 99999999',
        reg: /(\+965) ([0-9]{8})/
    },
    {
        name: 'Netherlands',
        iso2: 'nl',
        dialcode: '31',
        mask: '+31 99 99999999',
        reg: /(\+31) ([0-9]{2}) ([0-9]{8})/
    },
    {
        name: 'Oman',
        iso2: 'om',
        dialcode: '968',
        mask: '+\\968 9999 9999',
        reg: /(\+968) ([0-9]{4}) ([0-9]{4})/
    },
    {
        name: 'Qatar',
        iso2: 'qa',
        dialcode: '974',
        mask: '+\\974 99 999999',
        reg: /(\+974) ([0-9]{2}) ([0-9]{6})/
    },
    {
        name: 'Russia',
        iso2: 'ru',
        dialcode: '7',
        mask: '+7 999 999-99-99',
        reg: /(\+7)( )?([0-9]{3})( )?([0-9]{3})(-)?([0-9]{2})(-)?([0-9]{2})/
    },
    {
        name: 'Saudi Arabia',
        iso2: 'sa',
        dialcode: '966',
        mask: '+\\966 99 999 9999',
        reg: /(\+966)( )?([0-9]{2})( )?([0-9]{3})( )?([0-9]{4})/
    },
    {
        name: 'Spain',
        iso2: 'es',
        dialcode: '34',
        mask: '+34 999 999 999',
        reg: /(\+34)( )?([0-9]{3})( )?([0-9]{3})( )?([0-9]{3})/
    },
    {
        name: 'Turkey',
        iso2: 'tr',
        dialcode: '90',
        mask: '+\\90 999 999 99 99',
        reg: /(\+90)( )?(501|505|506|507|551|552|553|554|555|559|530|531|532|533|534|535|536|537|538|539|561|540|541|542|543|544|545|546|547|548|549)( )?([0-9]{3})( )?([0-9]{2})( )?([0-9]{2})/
    },
    {
        name: 'Ukraine',
        iso2: 'ua',
        dialcode: '380',
        mask: '+380 99 999 99 99',
        reg: /(\+380) ([0-9]{2}) ([0-9]{3}) ([0-9]{2}) ([0-9]{2})/
    },
    {
        name: 'United Arab Emirates',
        iso2: 'ae',
        dialcode: '971',
        mask: '+\\971 99 999 999',
        reg: /(\+971) (50|52|54|55|56|58) ([0-9]{3}) ([0-9]{4})/
    },
    {
        name: 'United Kingdom',
        iso2: 'uk',
        dialcode: '44',
        mask: '+44 9999 999999',
        reg: /(\+44) ([0-9]{4}) ([0-9]{6})/
    },
    {
        name: 'United States',
        iso2: 'us',
        dialcode: '1',
        mask: '+1 999 999-9999',
        reg: /(\+1) ([0-9]{3}) ([0-9]{3})-([0-9]{4})/
    },
];