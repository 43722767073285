import React, { useContext } from 'react'

// Components
import Consent from './consent.component';

// Contexts
import { DataContext } from '../_contexts/data.context';

const ConsentList = () => {
    const { form } = useContext(DataContext);

    if (!form?.Config.Form?.Consents) return '';
    return form.Config.Form.Consents.map((consent, index) => <Consent key={index} consent={consent} />);
};

export default ConsentList
