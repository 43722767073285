import React from 'react'

// Material UI
import Stack from '@mui/material/Stack';

const Spacer = (props) => {
    const { vertical } = props;
    return <Stack sx={{ flexGrow: 1 }} style={vertical ? { width: '100%' } : { height: '100%' }} />
}

export default Spacer;
