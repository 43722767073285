import React from 'react';

// Material UI
import Stack from '@mui/material/Stack';

// Components
import ActionButton from '../_components/action.button.component';

// Utilities and Helpers
import { DeepLinker } from '../_helpers/redirect.helper';
import FieldList from '../_components/field.list.component';

const ActionButtons = ({ buttons }) => {
  if (!buttons || buttons.length === 0) return '';

  var linker = new DeepLinker({
    onIgnored: function () {},
    onFallback: function () {
      ('dialog hidden or user returned to tab');
    },
    onReturn: function () {},
  });

  return (
    <Stack sx={{ flexDirection: 'column', width: '100%', gap: '16px', padding: '8px' }}>
      {buttons.map((buttongroup, groupindex) => (
        <Stack key={groupindex} sx={{ gap: '12px', width: '100%', flexDirection: 'row' }}>
          {buttongroup.map((button, rowindex) => (
            <ActionButton key={rowindex} button={button} linker={linker} />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

const Page = (props) => {
  const { page } = props;

  return (
    <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
      <Stack
        width={1}
        sx={{
          padding: 2,
          justifyContent: 'center',
          alignItems: 'center',
          boxSizing: 'border-box',
          overflow: 'auto',
        }}>
        <FieldList fields={page.Fields} />
      </Stack>
      <ActionButtons buttons={page.Buttons} />
    </Stack>
  );
};

export default Page;
