import React from 'react'

// Material UI
import { DialogContentText, DialogActions, DialogContent, DialogTitle, Button, Dialog as MuiDialog } from '@mui/material';

const Dialog = (props) => {
    const { dialogText, dialogTitle, fullScreen, title, onClose, open } = props;

    return (
        <MuiDialog fullScreen={fullScreen} open={open} onClose={onClose} >
            <DialogTitle>{dialogTitle || ''}</DialogTitle>
            <DialogContent>
                {
                    title && <DialogContentText>{title}</DialogContentText>
                }
                <DialogContentText sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }} variant={title ? null : 'body2'}>{dialogText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='primary'>KAPAT</Button>
            </DialogActions>
        </MuiDialog>
    )
}

export default Dialog;