import { useState, useCallback } from 'react'
import { useFormInput } from './useFormInput';
import { useFormConsent } from './useFormConsent';

export function useForm() {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState([]);
    const [validating, setValidating] = useState(false);

    const handleError = useCallback((name, isvalid) => {
        let tmperrors = errors;
        const index = tmperrors.findIndex(error => error === name);

        if (!isvalid) {
            if (index < 0) tmperrors.push(name);
        } else {
            if (index > -1) tmperrors.splice(index, 1);
        }

        setErrors(tmperrors);
    }, [errors]);

    const clearForm = useCallback((language) => {
        setValues(prev => { return { ...prev, FormData: [] } });
        setErrors([]);
    }, []);

    const useInput = (field) => useFormInput({
        field,
        values,
        validating,
        setValues,
        setValidating,
        handleError
    });

    const useConsent = (consent) => useFormConsent({
        consent,
        values,
        validating,
        setValues,
        setValidating,
        handleError
    });

    return {
        values,
        errors,
        validating,
        setValues,
        setErrors,
        clearForm,
        setValidating,
        useInput,
        useConsent
    };
}