import React, { useContext } from 'react'

// Material UI
import Stack from '@mui/material/Stack';

// Components
import ActionButton from './action.button.component';

// Contexts
import { DataContext } from '../_contexts/data.context';

// Utilities and Helpers
import { DeepLinker } from '../_helpers/redirect.helper';

const ActionButtons = () => {
    const { form } = useContext(DataContext);

    if (!form?.Config.Form.Buttons || form?.Config.Form.Buttons === []) return '';

    const buttons = form.Config.Form.Buttons;

    var linker = new DeepLinker({
        onIgnored: function () {
        },
        onFallback: function () {
            ('dialog hidden or user returned to tab');
        },
        onReturn: function () {
        },
    });

    return (
        <Stack spacing={2} direction='column' sx={{ width: '100%', padding: 1 }}>
            {
                buttons.map((buttongroup, groupindex) =>
                    <Stack direction='row' spacing={1} key={groupindex} sx={{ width: '100%' }}>
                        {
                            buttongroup.map((button, rowindex) => <ActionButton key={rowindex} button={button} linker={linker} />)
                        }
                    </Stack>
                )}
        </Stack>
    )
}
export default ActionButtons;
