import React, { useContext } from 'react';
import { orderBy } from 'lodash';

// Material UI
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';

// Fields
import TextInputField from './fields/text.field.component';
import SelectField from './fields/select.field.component';
import EmailField from './fields/email.field.component';
import InfoText from './fields/info.text.component';
//import IntPhoneField from './fields/intphone.field.component';
import RadioField from './fields/radio.field.component';
import DateField from './fields/date.field.component';
import MaskedDateField from './fields/maskeddate.field.component';
import MaskedPhoneField from './fields/maskedphone.field.component';
import MaskedTextField from './fields/maskedtext.field.component';
//import RegexPhoneField from './fields/regexphone.field.component';

import { DataContext } from '../_contexts/data.context';
import { Typography } from '@mui/material';

const InputField = (props) => {
	switch (props.field.InputType) {
		case 'textarea':
			return <TextInputField multiline {...props} />;
		case 'masked-date':
			return <MaskedDateField {...props} />;
		case 'age-control':
			return <DateField {...props} />;
		case 'age-control18':
			return <DateField {...props} />;
		case 'masked-text':
			return <MaskedTextField {...props} />;
		//case 'regex-phone': return <RegexPhoneField {...props} />
		case 'tr-mobile':
			return <MaskedPhoneField {...props} />;
		//case 'int-phone': return <IntPhoneField {...props} />
		case 'phone':
			return <MaskedPhoneField {...props} />;
		case 'date':
			return <DateField {...props} />;
		case 'text':
			return <TextInputField {...props} />;
		case 'select':
			return <SelectField {...props} />;
		case 'infotext':
			return <InfoText {...props} />;
		case 'email':
			return <EmailField {...props} />;
		case 'radio':
			return <RadioField {...props} />;
		default:
			return '';
	}
};

const Field = (props) => {
	const { field, ...rest } = props;
	const { formGetResponse, formSendResponse } = useContext(DataContext);

	switch (field.FieldType) {
		case 'text':
			return <InfoText field={field} />;
		case 'input':
			return <InputField field={field} {...rest} />;
		case 'image':
			return <img style={{ width: '90%' }} src={field.Label} alt={field.Label} />;

		case 'surveyResult':
			const itemCount = field.Values.length;
			const labelPercentage = 100 / itemCount;
			const colors = ['#001d97', '#a90432', '#5E3B8E', '#F76C3B', '003C43', 'A34343'];
			const values = field.Values.map((text) => {
				let message = text;
				if (formSendResponse) {
					if (text.includes('@')) {
						message.split(' ').map((param) => {
							if (param.startsWith('@')) message = message.replace(param, formSendResponse[param.substr(1)]);
							return null;
						});
					}
					return message;
				} else {
					if (text.includes('@')) {
						message.split(' ').map((param) => {
							if (param.startsWith('@')) message = message.replace(param, formGetResponse[param.substr(1)]);
							return null;
						});
					}
					return message;
				}
			});

			return (
				<div style={{ padding: '16px 0', width: '100%' }}>
					<div style={{ width: '100%', display: 'flex' }}>
						{field.ValueTexts.map((v, i) => (
							<Typography textAlign={'center'} color='primary' variant='body1' key={v} alignContent={'center'} width={`${labelPercentage}%`}>
								{v}
							</Typography>
						))}
					</div>
					<div style={{ width: '100%', height: '40px', display: 'flex' }}>
						{values.map((v, i) => (
							<div style={{ width: `${v}%`, backgroundColor: `${colors[i]}`, height: '100%', color: '#ffffff', alignContent: 'center', textAlign: 'center' }}>
								<Typography textAlign={'center'} color='primary' variant='body1' key={v} alignContent={'center'} width='100%'>
									{`${v}%`}
								</Typography>
							</div>
						))}
					</div>
				</div>
			);
		default:
			return '';
	}
};

const FieldList = (props) => {
	const { fields } = props;

	const useStyles = makeStyles((theme) => ({
		textfield: {
			color: theme.palette.text.primary,
			'& .MuiOutlinedInput-root': {
				'& .MuiOutlinedInput-notchedOutline': {
					border: `1px solid ${theme.palette.primary.main}`,
				},
			},
		},
		input: {
			zIndex: 999,
		},
		select: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		radiolabel: {
			color: theme.palette.text.primary,
		},
		native: {
			width: 50,
			height: 30,
			padding: 8,
		},
		nativeRoot: {
			padding: 0,
			'& + svg': {
				display: 'none',
			},
		},
		nativeSelect: {
			padding: 0,
			lineHeight: 0,
			height: 11,
		},
		positionStart: {
			position: 'relative',
		},
	}));

	const classes = useStyles();

	if (!fields) return '';

	const sortedFields = orderBy(fields, ['Order'], ['asc']);

	return sortedFields.map((field, index) => (
		<Stack key={index} width={1} py={1} alignItems='center'>
			<Field field={field} key={index} classes={classes} />
		</Stack>
	));
};

export default FieldList;
