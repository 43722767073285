import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';

// Api
import { post /*getdatafromip*/ } from '../_api/api';

// Helpers
import { getHash, getOSType } from '../_helpers/utility.helper';

// Material UI
import { createTheme } from '@mui/material/styles';

// Hooks
import { useForm } from '../_forms/useForm';

import UrlSearchParams from './urlsearchparams.js';

export const DataContext = createContext();

export const DataProvider = (props) => {
	const offerhash = getHash();
	const isMobile = getOSType();
	const params = useMemo(() => new UrlSearchParams(window.location.search), []);

	const [appState, setAppState] = useState({ state: 'resting', submitting: false, error: null });
	const [formGetResponse, setFormGetResponse] = useState(null);
	const [formSendResponse, setFormSendResponse] = useState(null);
	//const [countrycode, setCountrycode] = useState('tr');
	const [theme, setTheme] = useState(null);
	const [form, setForm] = useState(null);

	const { values, errors, setValues, setValidating, useInput, useConsent } = useForm();

	useEffect(() => {
		const updateTheme = async () => {
			setTheme(
				createTheme({
					palette: form.Config?.Theme?.palette,
					components: {
						MuiFormHelperText: {
							styleOverrides: {
								root: {
									color: form.Config?.Theme?.palette?.primary?.main,
								},
							},
						},
						MuiInputLabel: {
							styleOverrides: {
								root: {
									color: form.Config?.Theme?.palette?.primary?.main,
								},
							},
						},
						MuiInputBase: {
							styleOverrides: {
								input: {
									'&-webkit-autofill': {
										backgroundColor: 'transparent !important',
									},
								},
							},
						},
						MuiInput: {
							styleOverrides: {
								root: {
									color: form.Config?.Theme?.palette?.primary?.main,
									'&::before': {
										borderBottom: `1px solid ${form.Config?.Theme?.palette?.primary?.main}`,
									},
									'&::after': {
										borderBottom: `1px solid ${form.Config?.Theme?.palette?.primary?.main}`,
									},
								},
							},
						},
					},
					MuiTextField: {
						styleOverrides: {
							root: {
								'& .MuiOutlinedInput-root': {
									'&::before': {
										borderBottom: `1px solid ${form.Config?.Theme?.palette?.primary?.main}`,
									},
									'&::after': {
										borderBottom: `1px solid ${form.Config?.Theme?.palette?.primary?.main}`,
									},
									'& fieldset': {
										borderColor: form.Config?.Theme?.palette?.primary?.main,
									},
									'&:hover fieldset': {
										borderColor: form.Config?.Theme?.palette?.primary?.main,
									},
									'&.Mui-focused fieldset': {
										borderColor: form.Config?.Theme?.palette?.primary?.main,
									},
								},
							},
						},
						MuiButton: {
							styleOverrides: {
								root: {
									textTransform: 'none',
								},
							},
						},
						MuiSvgIcon: {
							styleOverrides: {
								root: {
									zIndex: 1000,
								},
							},
						},
					},
				})
			);
		};

		if (form) updateTheme();
	}, [form, setTheme]);

	/*useEffect(() => {
        const fetchCountryCode = async () => {
            getdatafromip('https://ipapi.co/json/')
                .then(response => setCountrycode(response.country_code.toLowerCase()))
                .catch(() => setCountrycode('tr'));
        }

        fetchCountryCode();
    }, [setCountrycode]);
    */

	useEffect(() => {
		const fetchForm = async () => {
			setAppState((prev) => {
				return { ...prev, state: 'checking', submitting: false, error: null };
			});

			const additionalparams = params.getAll();

			post(`Form/get/${offerhash}`, additionalparams)
				.then((getresponse) => {
					setForm(getresponse.Form);
					console.log(getresponse);
					document.body.style.backgroundColor = getresponse.Form.Config?.Theme?.palette.background.paper;
					if (isMobile !== 4) {
						if (getresponse.Form.Config.Theme.BgImageMobile)
							document.body.style.backgroundImage = `url(${getresponse.Form.Config.Theme.BgImageMobile})`;
					} else {
						if (getresponse.Form.Config.Theme.BgImageWeb) document.body.style.backgroundImage = `url(${getresponse.Form.Config.Theme.BgImageWeb})`;
					}
					setFormGetResponse(getresponse);
					if (getresponse.Values) {
						if (getresponse.SendFormIfValuesOK) {
							post(`Form/send/${offerhash}`, {
								...additionalparams,
								Msisdn: getresponse.Values.Data?.Msisdn || null,
								FormData: { ...getresponse.Values.Data },
							})
								.then((sendresponse) => {
									setFormSendResponse(sendresponse);
									if (sendresponse.Page)
										setAppState((prev) => {
											return { ...prev, state: 'page', submitting: false };
										});
									else if (sendresponse.ReturnMessage)
										setAppState((prev) => {
											return { ...prev, state: 'success', submitting: false };
										});
									else
										setAppState((prev) => {
											return { ...prev, state: 'error', submitting: false };
										});
								})
								.catch((error) =>
									setAppState((prev) => {
										return { ...prev, state: 'error', submitting: false, error: error };
									})
								);
						} else {
							setValues({ FormData: { ...getresponse.Values.Data } });
							if (getresponse.Page)
								setAppState((prev) => {
									return { ...prev, state: 'page', submitting: false };
								});
							else
								setAppState((prev) => {
									return { ...prev, state: 'form', submitting: false };
								});
						}
					} else {
						setValues({ FormData: { ...additionalparams } });
						if (getresponse.Page)
							setAppState((prev) => {
								return { ...prev, state: 'page', submitting: false };
							});
						else
							setAppState((prev) => {
								return { ...prev, state: 'form', submitting: false };
							});
					}
				})
				.catch((error) => setAppState({ state: 'error', error: error }));
		};

		fetchForm();
	}, [offerhash, setValues, params]);

	const submitForm = useCallback(
		(externalUrl) => {
			const additionalparams = params.getAll();

			const valid = !Boolean(errors.length);
			setValidating(true);

			if (valid) {
				setAppState((prev) => {
					return { ...prev, submitting: true, error: null };
				});
				// replace ' and " characters with empty string from all fields in values. Replace \ with /.
				Object.keys(values.FormData).forEach((key) => {
					if (typeof values.FormData[key] === 'string') {
						values.FormData[key] = values.FormData[key].replace(/["']+/g, '').replace(/\\/g, '/');
					}
				});

				post(externalUrl || `Form/send/${offerhash}`, { ...additionalparams, Msisdn: values.FormData?.Msisdn || null, ...values })
					.then((sendresponse) => {
						setFormSendResponse(sendresponse);

						if (sendresponse.Page)
							setAppState((prev) => {
								return { ...prev, state: 'page', submitting: false };
							});
						else if (sendresponse.ReturnMessage)
							setAppState((prev) => {
								return { ...prev, state: 'success', submitting: false };
							});
						else
							setAppState((prev) => {
								return { ...prev, state: 'error', submitting: false };
							});
					})
					.catch((error) =>
						setAppState((prev) => {
							return { ...prev, state: 'form', submitting: false, error: error };
						})
					);
			}
		},
		[setValidating, errors, values, offerhash, params]
	);

	return (
		<DataContext.Provider
			value={{
				form,
				theme,
				appState,
				//countrycode,
				formGetResponse,
				formSendResponse,
				useInput,
				useConsent,
				submitForm,
				setAppState,
			}}>
			{props.children}
		</DataContext.Provider>
	);
};
