import React, { useState, useContext } from 'react';

// Components
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from './dialog.component';
import LoadingButton from '@mui/lab/LoadingButton';

// Contexts
import { DataContext } from '../_contexts/data.context';

const ActionButton = (props) => {
    const { submitForm, appState } = useContext(DataContext);
    const { button, linker, color } = props;

    const { Label, Type, Action, Variant, Size, disabled } = button;

    const [open, setOpen] = useState(false);
    const handleConsentDialog = () => setOpen(!open);

    const handleClick = (type, action) => {
        switch (type) {
            case 'deeplink': linker.openURL(action); break;
            case 'history-go': window.history.go(action); break;
            case 'redirect': window.location = action; break;
            case 'submit': submitForm(); break;
            case 'external-submit': submitForm(action); break;
            case 'modal': handleConsentDialog(); break;
            default: return ''
        }
    }

    // If type is 'submit' return SubmitButton else return button with its props
    if (Type === 'submit') return (
        <Stack sx={{ position: 'relative', width: '100%' }}>
            <LoadingButton
                fullWidth
                variant={Variant}
                disabled={appState.submitting}
                size={Size || 'medium'}
                loading={appState.submitting}
                onClick={() => handleClick(Type, Action)}>
                {Label}
            </LoadingButton>
        </Stack>
    )

    else {
        return <>
            <Button
                fullWidth
                size={Size || 'medium'}
                color={color}
                disabled={disabled}
                variant={Variant}
                onClick={() => handleClick(Type, Action)}>
                {Label}
            </Button>
            {
                Type === 'modal' &&
                <Dialog
                    open={open}
                    fullScreen
                    onClose={handleConsentDialog}
                    dialogText={Action}
                    dialogTitle={Label}
                />
            }
        </>
    }
}

// Set the default props of Button
ActionButton.defaultProps = {
    size: 'large',
    color: 'primary'

}
export default ActionButton;