import React, { useContext } from 'react';

// Material UI
import TextField from '@mui/material/TextField';

// Contexts
import { DataContext } from '../../_contexts/data.context';

const TextInputField = (props) => {
  const { appState, useInput } = useContext(DataContext);

  const { field, multiline, classes } = props;
  const Shrink = field.PlaceHolder ? { shrink: true } : null;

  return (
    <TextField
      name={field.Name}
      label={field.Label}
      placeholder={field.PlaceHolder}
      disabled={field.Disabled || appState.submitting}
      fullWidth
      multiline={multiline}
      rows={multiline && 4}
      variant={field.Variant}
      InputLabelProps={Shrink}
      className={classes.textfield}
      InputProps={{
        classes: {
          input: classes.input,
        },
      }}
      {...useInput(field)}
    />
  );
};

export default TextInputField;
