import React from 'react'

// Material UI
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';

// Components
import Spacer from '../_components/spacer.component';

const Error = (props) => {
    const { error } = props;

    return (
        <Stack sx={{ boxSizing: 'border-box', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', padding: '20px' }}>
            <Spacer vertical />
            <img style={{ width: '160px', marginBottom: '8px' }} src={'/error.png'} alt='Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.' />
            <Typography key={'caption'} align='center' variant='body1' gutterBottom>Bir hata oluştu!</Typography>
            <Spacer vertical />
            <Typography key={'title'} align='center' variant='caption' color='inherit' gutterBottom>{error.message}</Typography>
        </Stack>
    );
}

export default Error;