import React, { useContext } from 'react'

// Material UI
import TextField from '@mui/material/TextField';
import InputMask from "react-input-mask";

// Contexts
import { DataContext } from '../../_contexts/data.context';

const MaskedDateField = (props) => {
    const { field, classes } = props;
    const { appState, useInput } = useContext(DataContext);

    return (
        <InputMask
            autoComplete='off'
            autoCorrect='off'
            mask='99/99/9999'
            maskChar={null}
            disabled={field.Disabled || appState.submitting}
            {...useInput(field)}>
            {(inputProps) =>
                <TextField
                    name={field.Name}
                    label={field.Label}
                    disabled={field.Disabled || appState.submitting}
                    fullWidth
                    variant={field.Variant}
                    placeholder={field.PlaceHolder}
                    className={classes.textfield}
                    InputProps={{
                        classes: {
                            input: classes.input,
                        }
                    }}
                    type='tel'
                    {...inputProps}
                />}
        </InputMask>
    )
}

export default MaskedDateField;