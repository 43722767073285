import React, { useContext } from 'react'

// Material UI
import {
    InputLabel,
    RadioGroup,
    FormHelperText,
    FormControlLabel,
    FormControl,
    Radio
} from '@mui/material';

// Contexts
import { DataContext } from '../../_contexts/data.context';

const RadioField = (props) => {
    const { field, classes } = props;
    const { appState, useInput } = useContext(DataContext); // Extract Forms and Credentials from context
    const { value, onChange, onFocus, onBlur, error, required, helperText } = useInput(field);

    return (
        <div className={classes.select}>
            <InputLabel disabled={field.isDisabled || appState.submitting} required={required} sx={{ textOverflow: 'inherit', whiteSpace: 'inherit' }}>{field.Label}</InputLabel>
            <FormControl component="fieldset">
                <RadioGroup aria-label={field.Name} name={field.Name} value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur}>
                    {
                        field.ValueTexts.map((Value, i) => (
                            <FormControlLabel
                                className={classes.radiolabel}
                                disabled={appState.submitting}
                                key={field.Values[i]}
                                value={field.Values[i]}
                                control={<Radio disabled={appState.submitting} color='primary' style={{ marginLeft: '16px' }} />}
                                label={Value}
                                labelPlacement='end'
                            />
                        ))
                    }
                </RadioGroup>
                <FormHelperText error={error} disabled={appState.submitting} style={{ paddingLeft: '16px' }}>{helperText}</FormHelperText>
            </FormControl>
        </div>
    );
}

export default RadioField;