const crypto = require("crypto");

const tobuffer = function (hexString) {
  hexString = hexString.replace(/^0x/, "");
  if (hexString.length % 2 !== 0) {
    console.log(
      "WARNING: expecting an even number of characters in the hexString"
    );
  }
  var bad = hexString.match(/[G-Z\s]/i);
  if (bad) {
    console.log("WARNING: found non-hex characters", bad);
  }
  var pairs = hexString.match(/[\dA-F]{2}/gi);
  var integers = pairs.map(function (s) {
    return parseInt(s, 16);
  });
  var array = Buffer.from(new Uint8Array(integers));
  var props = Buffer.from(new Uint8Array([16, 16]));
  var response = Buffer.concat([props, array]);
  return response;
};
const tobase64 = function (str) {
  return Buffer.from(str, "utf8").toString("base64");
};

const AESGCM = () => {
  const K1 = Buffer.from(new Uint8Array([71, 89, 104, 55, 102, 85, 43, 50]));
  const K2 = Buffer.from(new Uint8Array([64, 121, 115, 101, 89, 43, 33, 55]));
  const K3 = Buffer.from(new Uint8Array([33, 66, 53, 120, 115, 113, 95, 33]));
  const K4 = Buffer.from(new Uint8Array([70, 102, 116, 102, 55, 98, 89, 74]));
  const KEY = Buffer.concat([K1, K2, K3, K4]);

  function encrypt(message) {
    const IV = crypto.randomBytes(16);
    const cipher = crypto.createCipheriv("aes-256-gcm", KEY, IV);
    let encrypted = cipher.update(message, "utf8", "hex");
    encrypted += cipher.final("hex");
    const tag = cipher.getAuthTag();
    return tobase64(
      tobuffer(IV.toString("hex") + encrypted + tag.toString("hex"))
    );
  }

  return {
    encrypt
  }
};

export default AESGCM;
