import React, { useContext } from 'react';

// Material UI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// Components
import FieldList from '../_components/field.list.component';
import ConsentList from '../_components/consent.list.component';
import ActionButtons from '../_components/action.buttons.component';
import Dialog from '../_components/dialog.component';

// Contexts
import { DataContext } from '../_contexts/data.context';

const Form = () => {
  const { appState, setAppState, form } = useContext(DataContext);

  var caption = '';
  var title = '';

  if (appState.error) {
    if (appState.error.status === 400) {
      caption = 'Beklenmeyen bir hata oluştu';
      title = 'Şu anda hizmet veremiyoruz. Lütfen servis yetkilisi ile iletişime geçin';
    } else {
      caption = 'Beklenmeyen bir hata oluştu - ' + appState.error.status;
      title = 'Şu anda hizmet veremiyoruz. Lütfen servis yetkilisi ile iletişime geçin';
    }
  }

  const handleClose = () =>
    setAppState((prev) => {
      return { ...prev, error: null };
    });

  return (
    <Stack
      direction="column"
      boxSizing="border-box"
      sx={{
        width: '100%',
        padding: 2,
        pb: 12,
        boxSizing: 'border-box',
      }}>
      <Box flexGrow={1}>
        <FieldList fields={form?.Config?.Form?.Fields} />
        <ConsentList />
      </Box>
      <ActionButtons />
      <Dialog open={Boolean(appState.error)} onClose={handleClose} captionText={caption} title={title} dialogText="Lütfen tekrar deneyiniz" />
    </Stack>
  );
};

export default Form;
