import React, { useContext } from 'react';

// Material UI
import TextField from '@mui/material/TextField';

// Contexts
import { DataContext } from '../../_contexts/data.context';

const DateField = (props) => {
  const { field, classes } = props;
  const { appState, useInput } = useContext(DataContext);
  const { value, onChange, onFocus, onBlur, error, required, helperText } = useInput(field);

  return (
    <TextField
      autoComplete="off"
      autoCorrect="off"
      name={field.Name}
      label={field.Label}
      placeholder={field.PlaceHolder}
      disabled={field.Disabled || appState.submitting}
      className={classes.textfield}
      InputProps={{
        classes: {
          input: classes.input,
        },
      }}
      fullWidth
      variant={field.Variant}
      type="date"
      error={error}
      required={required}
      helperText={helperText}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
    />
  );
};

export default DateField;
