import React from 'react'

// Material UI
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const Splash = () =>
    <Stack sx={{ boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        <CircularProgress size={40} />
    </Stack>

export default Splash;