import React, { useContext } from 'react'

// Material UI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { DataContext } from '../_contexts/data.context';

const Success = () => {
    const { form, formSendResponse } = useContext(DataContext);

    return (
        <Stack sx={{ flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', gap: '24px' }}>
            {
                (form.Config.Form.Fields[0].FieldType === 'image') &&
                <img src={form.Config.Form.Fields[0].Label} alt="Logo" style={{ height: '100px' }} />
            }
            <Typography color='primary' fullWidth textAlign='center' variant='h6' sx={{ px: 2 }}>{formSendResponse.ReturnMessage}</Typography>
        </Stack>
    );
}

export default Success;

