class UrlSearchParams {
  constructor(query) {
    this.query = query;
  }
  getSearchObject = () => {
    const { query } = this;
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
      : {};
  };
  getAll = () => {
    const searchParams = this.getSearchObject();
    return searchParams;
  }
  get = param => {
    const searchParams = this.getSearchObject();
    return searchParams[param];
  };
  setUrl = (param, value) => {
    const searchParams = this.getSearchObject();
    searchParams[param] = value;
    return Object.keys(searchParams)
      .map(key => key + "=" + searchParams[key])
      .join("&");
  };
}

export default UrlSearchParams;